main {
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  background-color: #19232C;
}
@media (max-width: 991px){
  .background {
      background-image: none;
  }
}