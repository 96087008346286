.projectPage {
    height: fit-content;
    min-height: 100vh;
    background-image: linear-gradient(to right, #172856, #070d1d);
}
.back {
    display: block;
    font-size: 15px;
    padding: 15px;
    color: white;
    cursor: pointer;
}
.back:hover {
    color: orange;
}
#images {
    width: 100px;
    background-color: brown;
}