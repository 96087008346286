#projects {
    background-color: #0F151D;
}
#projects > .container > .projects-container > .project {
    display: flex;
    margin-bottom: 5rem;
    justify-content: space-between;
}
#projects > .container > .projects-container > .project > .details {
    position: relative;
    flex-grow: 2;
    z-index: 10;
}
#projects > .container > .projects-container > .project > .details > .detail-content {
    width: 100%;
}
.skill-box {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    position: relative;
    margin-bottom: 1rem;
}
.skill-box > .skill {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 2px solid #3FB984;
    border-color: rgba(0, 128, 98, 0.566);
    border-radius: 10px;
    padding: 5px 10px;
}
#projects > .container > .projects-container > .project > .image {
    position: relative;
    max-width: 40%;
}

#projects > .container > .projects-container > .project > .image > img {
    max-width: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
    position: relative;
    z-index: 10;
}

#projects > .container > .projects-container > .project:nth-child(1) > .image::after {
    content: '';
    position: absolute;
    border-radius: 0.5rem;
    top: 25px;  
    right: 10%;   
    width: 450px;  
    height: 300px; 
    background-color: #3FB98497;
    z-index: 1;
    transform: rotate(10deg);
}
.subProjects {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.subProject {
    position: relative;
    padding-left: 20px;
    width: 50%;
}
.subProject::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 5%;
    width: 4px;
    height: 90%;
    background-color: #F69400;
}
.skill-icon {
    max-width: 30px;
    max-height: 30px;
    border-radius: 0.25rem;
}
.skill-text {
    margin: 0;
}
/* #projects > .container > .projects-container > .project:nth-child(2) > .details::after {
    content: '';
    position: absolute;
    border-radius: 0.5rem;
    top: 50%;  
    left: -85%;   
    width: 80%;  
    height: 110%; 
    background-color: #3fb98497;
    z-index: 1;
    transform: rotate(-20deg);
} */

#projects > .container > .projects-container > .project:nth-child(3) > .image::after {
    content: '';
    position: absolute;
    border-radius: 0.5rem;
    top: 10px;  
    /* left: 135px;    */
    right: 10%;   
    width: 450px;  
    height: 300px; 
    background-color: #3FB98497;
    z-index: 1;
    transform: rotate(-10deg);
}

#projects > .container > .modal {
    display: block;
    position: fixed;
    padding: 20px;
    height: 80%;
    width: 90%;
    left: 5%;
    top: 10%;
    background-color: white;
    border-radius: 0.5rem;
    color: #0F151D;
    box-shadow: 0 0 20px 10px #0F151D;
}


#projects > .container > .modal > header {
    display: flex;
    justify-content: space-between;
}
#projects > .container > .modal > .content {
    display: flex;
    justify-content: space-between;
}
#projects > .container > .modal > .content > .details {
    flex-grow: 2;
    max-width: 50%;
    max-height: 650px;
    overflow: auto;
}
#projects > .container > .modal > .content > .details > .detail-long > pre {
    text-wrap: wrap;
    font-family: 'Poppins';
    font-size: 1.1rem;
}
#projects > .container > .modal > .content > .details > .link-section {
    min-height: fit-content;
    margin-top: auto;
}
#projects > .container > .modal > .content > .images {
    position: relative;
    text-align: center;
    width: 50%;
    max-height: 600px;
}
#projects > .container > .modal > .content > .images > img {
    max-width: 100%;
    max-height: 100%;
}
#projects > .container > .modal > .content > .images > .image-thumbnails {
    max-width: 100%;
}
#projects > .container > .modal > .content > .images > .image-thumbnails > img {
    max-width: 15%;
    max-height: 150px;
    border-bottom: 4px solid transparent;
}
#projects > .container > .modal > .content > .images > .image-thumbnails > img.active {
    border-color: #3FB984;
}
.external-link {
    text-decoration: none;
    color: #0F151D;
}
.aws {
    color: #F69400;
}
.fa-xmark:hover {
    color: #3FB984;
    cursor: pointer;
}
@media (max-width: 768px) {
    .subProject {
        width: 100%;
    }
}
@media (max-width: 992px){
    .project {
        flex-direction: column;
    }
    #projects > .container > .projects-container > .project > .image {
        max-width: 100%;
    }
    #projects > .container > .projects-container > .project:nth-child(1) > .image::after {
        right: 0%;   
        width: 90%;  
        height: 90%; 
        background-color: #3FB98497;
        z-index: 1;
        transform: rotate(5deg);
    }

    #projects > .container > .projects-container > .project:nth-child(3) > .image::after {
        right: 0;
        width: 100%;  
        height: 100%; 
        transform: rotate(-6deg);
    }
}
@media (max-width: 1200px) {
    #projects > .container > .projects-container > .project:nth-child(1) > .image::after {
        height: 60%; 
        background-color: #3FB98497;
        z-index: 1;
        transform: rotate(5deg);
    }

    #projects > .container > .projects-container > .project:nth-child(3) > .image::after {
        right: 0;
        width: 100%;  
        height: 60%; 
    }
    #projects > .container > .modal > .content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    #projects > .container > .modal > .content > .details {
        max-width: 100%;
        max-height: fit-content;
    }
    #projects > .container > .modal > .content > .images {
        max-width: 100%;
        width: 100%;
        text-align: center;
    }
    .link-section {
        position: static;
        bottom: 20px;
    }
}