#hero {
    position: relative;
    overflow: hidden;
    padding: 0;
}
#hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150px;
    pointer-events: none;
    background: linear-gradient(#3fb9846b, transparent);
}
#hero > .container {
    display: flex;
    height: 700px;
    align-items: center;
}
#hero > .container > .details {
    flex-grow: 2;
    max-width: 50%;
}
#hero > .container > .details > div {
    font-size: 2rem;
}
#hero > .container > .details > #name {
    font-weight: 600;
    font-size: 60px;
    /* padding-bottom: 2rem; */
}
#hero > .container > .details > #name > .first-name {
    color: #3FB984;
}
#hero > .container > .details > h2 {
    font-weight: 600;
    font-size: 25px;
    margin-left: 25px;
    position: relative;
}
#hero > .container > .details > h2::before {
    content: '';
    background-color: #3FB984;
    position: absolute;
    left: -25px;
    top: 7px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}
#hero > .container > .details > .CTA {
    color: #3FB984;
}
#hero > .container > .image {
    flex-grow: 2;
    max-width: 50%;
    text-align: center;
}
#hero > .container > .image > p {
    font-size: 1.75rem;
}
#hero > .container > .image > img {
    max-height: 600px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    position: absolute;
    bottom: 0;
    right: 400px;
    filter: grayscale(100%) brightness(100%);
}
#hero > .container > .socials {
    display: flex;
    flex-direction: column;
    max-width: 40px;
    gap: 20px;
}
#hero > .container > .socials > a {
    display: flex;
    flex-direction: column;
}
#hero > .container > .socials > a:hover {
    background-color: #0F151D;
    border-radius: 50%;
}

@media (max-width: 768px) {
    #hero > .container {
        flex-direction: column;
        height: fit-content;
    }
    #hero > .container > .details {
        margin-top: 20px;
        max-width: 100%;
    }
    #hero > .container > .details > h2 {
        text-align: end;
    }
    #hero > .container > .details > h2::before {
        left: 35px;
    }
    #hero > .container > .image {
        max-width: 100%;

    }
    #hero > .container > .socials {
        max-width: 100%;
        flex-direction: row;
        padding-bottom: 20px;
    }
    #hero > .container > .socials > a > img {
        max-height: 40px;
    }
}