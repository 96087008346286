#header {
    backdrop-filter: blur(2px);
    position: sticky;
    top: 0;
    background-color: #19232cdc;
    /* background-color: transparent; */
    padding: 10px;
    z-index: 100;
    border-bottom: 1px solid #3fb98486;
}
#header > .container {
    display: flex;
    justify-content: center;
    align-items: center;
}
#header > .container > .fa-bars {
    display: none;
}
#header > .container > img {
    max-width: 50px;
}
#header > .container > nav > ul {
    list-style: none;
    display: flex;
    gap: 2rem;
    margin: 0;
}
#header > .container > nav > ul > li > a {
    font-size: 1.2rem;
    text-decoration: none;
    color: white;
}
#header > .container > nav > ul > li:hover > a{
    color: #3FB984;
    cursor: pointer;
}
#debug-line-top {
    background-color: blue;
    width: 100%;
    height: 4px;
    position: fixed;
    top: 10%;
}
#debug-line-bottom {
    background-color: red;
    width: 100%;
    height: 4px;
    position: fixed;
    bottom: 90%;
}
@media (max-width: 900px){
    #header {
        backdrop-filter: blur(5px);
        border-bottom: 2px solid #3FB984;
    }
    #header > .container {
        flex-direction: column;
        align-items: flex-start;
    }
    #header > .container > .fa-bars {
        display: block;
        align-self: flex-end;
    }
    #header > .container > nav > ul {
        flex-direction: column;
    }
    #header > .container > nav > ul > li {
        width: 100%;
    }
    #header > .container > nav > ul > li:last-of-type {
        padding-bottom: 50px;
    }
}