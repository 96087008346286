#experience {
    background-color: #0F151D;
}
.experience {
    display: flex;
    position: relative;
}
.experience > .dates {
    min-width: 200px;
    text-align: end;
    padding-right: 30px;
    padding-top: 6px;
}
.experience > .details {
    padding-left: 30px;
    padding-bottom: 40px;
    position: relative;
}
.experience > .details > h3::before {
    content: '';
    position: absolute;
    background-color: #3FB984;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    left: -12px;
    top: 6px;
}
.experience > .details > h3::after {
    content: '';
    position: absolute;
    background-color: #3FB984;
    width: 4px;
    height: 100%;
    left: -4px;
    top: 6px;
}
@media (max-width: 575px) {
    #experience .experience {
        flex-direction: column;
    }
}