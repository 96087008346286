.skill-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 120px;
    height: 120px;
    background-color: #0F151D;
    border-radius: 1rem;
    transition: all 0.5s ease-out;
}
.skill-container:hover,
.skill-container.active {
    filter: none;
    background-color: rgba(0, 128, 98, 0.277);
    cursor: pointer;
}
.skill-container > .skill-image {
    padding-top: 20px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
}
.skill-container > .skill-image > img {
    width: 50px;
    margin: auto;
    border-radius: 0.25rem;
}
.skill-container > .skill-name {
    text-align: center;
    padding-bottom: 10px;
}
@media (max-width: 384px) {
    .skill-container {
        width: 49%;
        margin-bottom: 10px;
    }
}