.skills-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* gap: 30px; */
    justify-content: space-evenly;
}

.skill-overview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(0, 128, 98, 0.167);
    border-radius: 1rem;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 1s ease-out;
}
.skill-overview.open {
    /* min-height: 120px; */
    max-height: 900px;
    padding: 20px;
}
.skill-overview > .skill-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 30px;
    margin-bottom: 1rem;
}
.skill-overview > .skill-header > .skill-header-name > img {
    margin-right: 0.5rem;
    max-width: 30px;
}
.skill-overview > .skill-header > i {
    width: 40px;
    font-size: 1.25rem;
    text-align: center;
}
.skill-overview > .skill-header > i:hover {
    cursor: pointer;
    color: orange;
}
.skill-overview h3 {
    color: white;
    font-size: 1rem;
    font-weight: 600;
}
.skill-projects {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 16px;
}
.skill-project {
    border: 2px solid #3FB984;
    border-radius: 10px;
    padding: 5px 10px;
    color: white;
    text-decoration: none;
}
/* .skill-project:hover {
    border: 2px solid #3FB984;
    background-color: #3FB984;
    cursor: pointer;
} */
.skill-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    padding: 16px;
    margin: 0;
}
@media (max-width: 384px) {
    .skill-projects {
        display: flex;
        flex-direction: column;
    }
}