
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0px;
  left: 0;
  background-color: #3FB984;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover,
.hover-underline-animation.active {
  color: #3FB984;
}

.hover-underline-animation:hover::after,
.hover-underline-animation.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.btn-primary {
  background-color: #3FB984;
  border: #3FB984;
}
.btn-primary:hover, 
.btn-primary:active, 
.btn:first-child:active,
.btn:focus-visible,
.btn:first-child:active:focus-visible {
  background-color: #3fb984b4;
  box-shadow: none;
}

section {
  padding-top: 48px;
  padding-bottom: 48px;
}

h2 {
  color: white;
  font-size: 2rem;
  margin-bottom: 48px;
}
h2::before {
  content: '</ ';
  color: #3FB984;
}
h3 {
  font-size: 1.75rem;
  color: #3FB984;
}
h4 {
  font-size: 1.25rem;
}

.link {
  cursor: pointer;
}
.special-green {
  color: transparent !important;
  background: linear-gradient( to right, #3FB984, #219c89);
  background-clip: text;
  -webkit-background-clip: text;
}